import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import MenuBar from "../components/MenuBar";
import TermsOfUse from "../components/TermsOfUse";
import { navigate } from "gatsby";
interface NorthstarLoginProps {
  setLoginStatus(status: boolean): void;
}
import { useAuthContext } from "../contexts/authContext";
import useNorthstar from "../hooks/northstarHook";

const enum State {
  Verifying = 1,
  RequestingAcceptance,
  Accepting,
  Accepted,
  Rejecting,
}

const TermsOfUsePage: React.FC<NorthstarLoginProps> = () => {
  const [displayTermsOfUse, setDisplayTermsOfUse] = useState(false);

  const [state, setState] = useState(State.Verifying);
  const [endpoint, setEndpoint] = useState("/api/tou/verify-acceptance");

  const { accessToken, logout } = useAuthContext();

  const { data, isLoading, error } = useNorthstar(endpoint, accessToken, {
    method: endpoint === "/api/tou/verify-acceptance" ? "get" : "post",
  });

  useEffect(() => {
    if (isLoading || state === State.RequestingAcceptance) return;

    if (error) return navigate("/error");

    if (data == null) return;

    switch (state) {
      case State.Verifying:
        switch (data?.accepted) {
          case true:
            setState(State.Accepted);
            break;

          case false:
            setState(State.RequestingAcceptance);
            break;

          default:
            break;
        }
        break;

      case State.RequestingAcceptance:
      case State.Accepted:
      case State.Rejecting:
        break;

      case State.Accepting:
        if (data?.id != null) setState(State.Accepted);
        break;

      default:
        navigate("/error");
    }
  }, [isLoading]);

  useEffect(() => {
    switch (state) {
      case State.Verifying:
        break;

      case State.RequestingAcceptance:
        setDisplayTermsOfUse(true);
        break;

      case State.Accepting:
        setEndpoint("/api/tou/record-acceptance");
        break;

      case State.Accepted:
        navigate("/");
        break;

      case State.Rejecting:
        navigate("/logout");
        break;
    }
  }, [state]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of Use</title>
      </Helmet>
      <main className="w-screen h-screen bg-off-white flex flex-center">
        <MenuBar NorthstarMenu={true} />
        {displayTermsOfUse ? (
          <TermsOfUse
            onAccept={() => {
              setState(State.Accepting);
            }}
            onDecline={() => {
              setState(State.Rejecting);
            }}
          />
        ) : (
          <></>
        )}
      </main>
    </>
  );
};

export default TermsOfUsePage;
